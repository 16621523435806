import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import Grid from "@mui/material/Grid"
import fp from "lodash/fp"

import type { DeviceGroupType } from "types/group.types"

import DeviceGroupDevicesTable from "widgets/device/DevicesTable/DeviceGroupDevicesTable"
import useDevices from "helpers/hooks/useDevices"
import { BodyContainer } from "widgets/styled/containers"
import MapSection from "widgets/common/MapSection"
import useGeolocations from "helpers/hooks/useGeolocations"
import GeoJSONLayers from "widgets/geo/GeoJSONLayers"
import { getMapCenterFromDeviceGeo } from "helpers/utils/map"

function GroupInfo() {
  const { deviceGroup, deviceGroupLoading } = useOutletContext<{
    deviceGroup: DeviceGroupType
    deviceGroupLoading: boolean
  }>()
  const {
    devsWRelsByIdWStates,
    lessIdByDeviceId,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useDevices()
  const deviceList = useMemo(
    () => deviceGroup.device_list as number[],
    [deviceGroup.device_list],
  )
  const groupDevices = useMemo(() => {
    if (devsWRelsByIdWStates && lessIdByDeviceId) {
      return deviceList
        .map((deviceId) => devsWRelsByIdWStates[lessIdByDeviceId[deviceId]])
        .filter((deviceData) => deviceData !== undefined)
    } else {
      return []
    }
  }, [devsWRelsByIdWStates, lessIdByDeviceId, deviceList])

  const devicesFirstFetch = useMemo(
    () => devicesFetching && fp.isEmpty(devsWRelsByIdWStates),
    [devsWRelsByIdWStates, devicesFetching],
  )

  const { geoData, isLoading: geoLoading } = useGeolocations()

  const devicesGeo = useMemo(() => {
    if (geoData) {
      const deviceListSet = new Set(deviceList)
      const filteredFeatures = geoData?.Device.features.filter((feature) =>
        deviceListSet.has(feature.properties.id),
      )
      return { Device: { ...geoData.Device, features: filteredFeatures } }
    }
  }, [geoData, deviceList])

  const center = useMemo(() => getMapCenterFromDeviceGeo(devicesGeo), [devicesGeo])

  const shouldRenderMap = useMemo(
    () => devicesGeo?.Device?.features && devicesGeo.Device.features.length > 0,
    [devicesGeo],
  )

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <Grid container spacing={2}>
        <MapSection
          center={center}
          isPending={devicesLoading || geoLoading || !devicesGeo}
          shouldRender={!!shouldRenderMap}
        >
          <GeoJSONLayers data={devicesGeo} />
        </MapSection>
        <Grid item xs={12} sx={{ mt: "1rem" }}>
          <DeviceGroupDevicesTable
            devices={groupDevices}
            isFetching={devicesFirstFetch}
            isLoading={devicesLoading || deviceGroupLoading}
            titleKey="device_groups.GROUP_DEVICES"
            group={deviceGroup}
          />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default GroupInfo
